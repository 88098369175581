.ResourceEntry {
    min-width: 60px;
}

.ResourceEntryHeader {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;
}

.ResourceEntryGrowth {
    font-size: 0.875em;
}

.ResourceEntryGrowthPerSecond {
    font-size: 0.80em;
    opacity: 70%;
}

.ResourceIcon {
    vertical-align: text-bottom;
}

.ResourceIcon.medium {
    width: 20px;
    height: 20px;
}

.ResourceIcon.small {
    width: 16px;
    height: 16px;
}

.ResourceIconContainer {
    white-space: nowrap;
}