.App .TooltipOverride {
    background-color: #3b404b;
    border-radius: 4px;
}

.GameTooltip {
    max-width: 400px;
    font-size: large;
    background-color: #3b404b;
}

.GameTooltip .disabled {
    opacity: 0.5;
}

.SpellTooltipContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    gap: 6px;
}

.SpellTooltipContainer .SpellName {
    font-weight: bold;
}

.SpellTooltipValuesContainer {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 0px;

    text-align: left;
    font-size: 0.8em;
}

.SpellTooltipValuesContainer .SpellValueName {
    opacity: 0.7;
}

.SpellTooltipValuesContainer .DurationValue {
    font-size: 1em;
}

.SpellTooltipCurrentTimeValuesContainer {
    margin: 8px;
    text-align: left;
}

.SpellCurrentCooldown,
.SpellCurrentDuration {
    font-size: 1.1em;
}

.HeroRecovering {
    font-size: 1.1em;
}

.TooltipUpgradeProductionTitle {
    margin-top: 16px;
    margin-bottom: -8px;
    font-size: 0.8em;
}

.HeroTooltipContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

/* .HeroTooltipTitle {} */

.HeroTooltipDetails {
    font-size: 0.8em;
}