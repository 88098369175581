.feedback {
  position: absolute;
  opacity: 0;
  text-shadow: 1px 1px black;
}

.feedback-appear {
  opacity: 1;
  transform: translateY(-20px);
}

.feedback-appear-active {
  opacity: 0;
  transform: translateY(-100px);
  transition: opacity 1000ms ease-in, transform 1000ms ease-out;
}

.FeedbackContainer {
  margin: 0;
  padding: 0;
  z-index: 100;

  pointer-events: none;
  user-select: none;
}

.HeroInteractionFeedbackAnchor {
  margin: 0;
  padding: 0;
  /* width: 100%; */
  /* height: 100%; */
  /* height: 0px;
  margin-top: -5px; */
}

.HeroInteractionFeedbackAnchor.fullsize {
  width: 100%;
}