.Background {
    width: 100%;
    height: 100%;
    background-color: rgb(30, 30, 30);
    padding: 10px;
    overflow-y: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;
}

.Background::-webkit-scrollbar {
    display: none;
}

/* .EntitiesContainer {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
} */