.HeroPanelSeparatorContainer {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.HeroPanel {
    margin: 0;
    padding: 8px;
    display: flex;
    flex-direction: row;
    overflow: hidden;

    width: 100%;
    height: calc(100% - 7px);
}

.HeroPanelColumn {
    flex: 1 0;
    overflow-y: auto;
}

.SpellsContainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 4px;
}

.SpellsTitle {
    display: block;
    font-size: 1.1em;
    margin-bottom: 8px;
}

.HeroPortrait {
    border: 1px rgb(124, 124, 124) solid;
    border-radius: 4px;
    width: 96px;
    height: 96px;
    margin: auto;
}

.HeroImage.small {
    width: 24px;
    height: 24px;
}

.HeroImage.full {
    width: 96px;
    height: 96px;
}

.HeroPanelSeparator {
    margin: 0px 0px 7px 0px;
}

.SpellIcon {
    vertical-align: text-bottom;
}

.SpellIcon.medium {
    width: 24px;
    height: 24px;
}

.SpellIcon.small {
    width: 20px;
    height: 20px;
}

.SpellIconContainer {
    white-space: nowrap;
}

.SpellButton .SpellIcon.small {
    margin-right: 3px;
}

.HeroActionTargetBuilding .BuildingIconContainer {
    white-space: normal;
}

.HeroActionTargetTitle {
    font-size: 0.875em;
}