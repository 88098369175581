.Card {
    width: 300px;
    height: calc(100% - 20px);
    margin: 10px 5px;
    align-self: stretch;
}

.CardHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 8px;
}

.CardHeader .middle {
    flex: 1;
    font-size: 1.2em;
}

.CardHeader .left {
    min-width: 32px;
    text-align: left;
}

.CardHeader .right {
    min-width: 32px;
    font-size: 1em;
    text-align: right;
    font-weight: bold;
}

.CardHeader .maxAmount {
    font-size: 0.75em;
    font-weight: normal;
    opacity: 35%;
}

.CardBody {
    margin: 8px;
    padding: 0px;
    display: flex;
    flex-direction: column;
}

.CardBodySeparator {
    margin: 4px 0px 12px 0px;
    opacity: 0.1;
}

.CardButtons {
    display: flex;
    flex-flow: column;
    gap: 5px;
    margin: 0px 32px 8px 32px;
}

.InteractionButtonsContainer {
    width: 100%;
    display: flex;
    gap: 6px;
}

.InteractionButtonContainer {
    flex-grow: 1;
}

.InteractionButton {
    height: 32px;
    width: 100%;
}

.HeroButtonContainer {
    height: 32px;
}

.HeroButton {
    padding: 0;
    width: 32px;
    height: 32px;
}

.HeroMoveIcon {
    width: 20px;
    height: 20px;
}

.HeroButtonContainer.fullsize {
    width: 100%;
}

.HeroButton.fullsize {
    padding: 0;
    width: 100%;
    height: 32px;
}

.BuildingIcon.medium {
    width: 24px;
    height: 24px;
}

.BuildingIcon.small {
    width: 20px;
    height: 20px;
}

.BuildingIconContainer {
    white-space: nowrap;
}

.BuildingStrike {
    font-size: 1.5em;
    color: rgb(255, 109, 109);
}