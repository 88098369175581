$top-bar-height: 100px;
$right-bar-width: 320px;

.App {
  display: flex;
  width: 100%;
  height: 100%;

  text-align: center;
  color: white;
  overflow: hidden;
}

.MainContainer {
  width: calc(100% - $right-bar-width);
  height: 100%;

  display: flex;
  flex-direction: column;
}

.TopBar {
  background-color: #3b404b;
  width: 100%;
  height: $top-bar-height;
  overflow-x: auto;
}

.Main {
  background-color: #121212;
  width: 100%;
  height: calc(100% - $top-bar-height);
  overflow: hidden;
}

.RightPanel {
  background-color: #282c34;
  width: $right-bar-width;
  height: 100%;
  overflow: auto;
}

.positive {
  color: rgb(0, 245, 0);
}

.negative {
  color: rgb(255, 109, 109);
}

.DebugPanel {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

$grid-columns: 12;
$grid-gutter-width: 1.5rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 980px,
  lg: 1280px,
  xl: 1580px,
  xxl: 2200px
);

$container-max-widths: (
  sm: 350px,
  md: 650px,
  lg: 970px,
  xl: 1290px,
  xxl: 1900px,
);

$info: #32a6ff;
$updated-colors: (
  "info": $info,
);


@import "bootstrap";

$theme-colors: map-merge($theme-colors, $updated-colors);