.HorizonalContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 32px;
}

.HorizonalContainer .ResourceEntry:first-child {
    margin-left: auto;
}

.HorizonalContainer .ResourceEntry:last-child {
    margin-right: auto;
}