:root {
    --hero-panel-height: 240px;
}

.RightPanelContainer {
    width: 100%;
    height: 100%;
}

.UpgradePanelContainer {
    height: calc(100% - var(--hero-panel-height));
    width: 100%;
}

.HeroPanelContainer {
    width: 100%;
    height: var(--hero-panel-height);
    margin: 0;
    padding: 0;
}