.ResourceTable {
    margin-top: 12px;
    flex: 1;
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
}

.ResourceTableLabel {
    padding: 0 4px 0 4px;
    font-size: 0.8em;
}

.ResourceTableValue {
    padding: 0 4px 0 4px;
    font-size: 0.8em;
}

.ResourceTableEntry {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;
}

.ResourceTablePerSecond {
    font-size: 0.75em;
    opacity: 70%;
}