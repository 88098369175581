.ResourceCostButtonContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.ResourceCostButtonContainer .ResourceIcon.small {
    margin-bottom: 1px;
}


.ResourceCostButtonEntry {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.TemplateStringContainer .ResourceIcon.small {
    margin-bottom: 2px;
}

.TemplateStringContainer .SpellIcon.small {
    margin-bottom: 1px;
}

.DurationUnit {
    font-size: smaller;
}

.TemplateStringContainer .highlight {
    font-weight: bold;
}